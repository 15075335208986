<template>
	<section class="video-grid-collection">
		<span class="anchor" id="video-grid-collection-anchor"></span>
		<div class="container">
			<div class="column-wrapper">
				<div class="column grid-collection">
					<transition name="slow-fade">
						<div
							class="items-wrapper"
							v-if="
								videoContentArray &&
									videoContentArray.length > 0
							"
						>
							<VideoOverviewItem
								v-for="item in videoContentArray"
								:key="item.mediaId"
								:data="item"
								:observer="observer"
							/>
						</div>
					</transition>
					<transition name="slow-fade">
						<div
							class="message no-items-found"
							v-if="showNoItemsFound && !showLazyLoadSpinner"
						>
							Geen video's meer gevonden..
						</div>
					</transition>
					<transition name="fade">
						<div
							class="lazyload-spinner"
							v-if="showLazyLoadSpinner"
						>
							<ul>
								<li></li>
								<li></li>
								<li></li>
								<li></li>
								<li></li>
								<li></li>
							</ul>
						</div>
					</transition>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
// @import component
import VideoOverviewItem from "@/components/VideoOverviewItem";

import { fetchVideoContentList } from "@streampac.io/chef_sp_1";

// import { fetchMultipleVideoAssets } from "@/dist";

export default {
	name: "SearchGridCollection",
	components: {
		VideoOverviewItem,
	},
	inject: ["isAuthenticated"],
	data() {
		return {
			showNoItemsFound: false,
			loadedAllItemFound: false,
			observer: null,
			totalItems: 0,
			totalLoadedItems: 0,
			catFilters: [],
			filterVal: [],
			showLazyLoadSpinner: false,
			data: null,
			videoContentArray: [],
			fetchDataParams: {
				filter: {
					searchTerm: "",
				},
				paging: {
					pageNumber: 1,
					pageSize: 1,
				},
				// orderBy: {
				// 	orderByField: "PublicationDate",
				// 	orderDesc: false,
				// },
				flattenFields: true,
			},
		};
	},
	props: {
		paging: {
			type: Object,
			required: true,
		},
		searchParams: {
			type: String,
			required: false,
		},
	},
	created() {
		this.observer = new IntersectionObserver(this.onElementObserved, {
			root: this.$el,
			threshold: 0.5,
		});

		if (this.searchParams) {
			this.fetchDataParams.filter.searchTerm = this.searchParams;
		}

		if (this.paging) {
			this.fetchDataParams.paging.pageNumber = this.paging.pageNumber;
			this.fetchDataParams.paging.pageSize = this.paging.pageSize;
		}

		setTimeout(() => {
			this.fetchVideoContentData();
		}, 200);
	},
	mounted() {},
	unmounted() {
		this.observer.unobserve(this.$el);
	},
	methods: {
		onElementObserved(entries) {
			entries.forEach(({ target, isIntersecting }) => {
				if (isIntersecting) {
					this.observer.unobserve(target);
					this.totalItems = this.totalItems + 1;
					this.totalLoadedItems = this.totalLoadedItems + 1;
				}
			});

			if (this.totalItems == this.fetchDataParams.paging.pageSize) {
				setTimeout(() => {
					this.fetchDataParams.paging.pageNumber =
						this.fetchDataParams.paging.pageNumber + 1;

					this.fetchVideoContentData(
						this.fetchDataParams.paging.pageNumber
					);
				}, 50);

				//reset count to 0 again
				this.totalItems = 0;
			}
		},
		fetchVideoContentData(val) {
			this.showLazyLoadSpinner = true;
			this.fetchDataParams.paging.pageNumber = val;

			if (val == undefined) this.fetchDataParams.paging.pageNumber = 1;

			let inputData = this.fetchDataParams;

			fetchVideoContentList(this.isAuthenticated, inputData, true).then(
				(response) => {
					//this.showLazyLoadSpinner = true;
					this.data = response.data;

					const paginationHeaders = JSON.parse(
						response.headers["x-pagination"]
					);

					this.totalCount = paginationHeaders.TotalCount;

					if (paginationHeaders.TotalCount == 0) {
						this.showNoItemsFound = true;
					}

					if (paginationHeaders.TotalCount == this.totalLoadedItems) {
						this.loadedAllItemFound = true;
					}

					this.processFetch();

					this.showLazyLoadSpinner = false;
				}
			);
		},
		processFetch() {
			let videoContentArray = this.videoContentArray;

			if (this.data) {
				this.data.forEach((item) => {
					videoContentArray.push(item);
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
