<template>
    <!-- <section class="section tmp-banner no-bg">
		<div class="container">
			<div class="column-wrapper">
				<div class="column intro-column">
					<h1 class="block-title">
						Search Delight
					</h1>
					<span class="block-subtitle">Find your class</span>

					<div class="search-meta-wrapper">
						<SearchBar />
					</div>
				</div>
			</div>
		</div>
	</section> -->

    <HeroPage v-if="pageData" :data="pageData" heroType="center" />


    <section class="section search-section">
        <div class="container">
            <div class="column-wrapper">
                <div class="column intro-column">
                    <div class="search-meta-wrapper">
                        <SearchBar />
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section search-query-msg">
        <div class="container">
            <div class="column-wrapper">
                <div class="column intro-column">
                    <div class="chosen-search-term" v-if="userInput">
                        <h2>Results for: "{{ userInput }}"</h2>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <SearchGridCollection :paging="paging" :searchParams="userInput" />
</template>

<script>
import SearchGridCollection from "@/components/SearchGridCollection";

import SearchBar from "@/components/SearchBar";

import HeroPage from "@/components/HeroPage";


export default {
    data() {
        return {
            userInput: this.$route.params.searchParam,
            paging: {
                pageNumber: 1,
                //Temp high page size because of pagination bug
                pageSize: 200,
            },
            toggleBar: false,
            pageData: {
                headerTitle: 'Search',
                headerSubtitle: 'Find your video'
            }
        };
    },
    inject: ["isAuthenticated"],
    components: {
        SearchGridCollection,
        SearchBar,
        HeroPage
    },
    created() { },
    methods: {},
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>

<style lang="scss">
.search-meta-wrapper {
    .search-wrapper {
        margin: 0 auto;
        width: 270px;
    }
}
</style>
